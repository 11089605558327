import Skeleton from '@mui/material/Skeleton';
import { GenericButton } from '../generic_components';
import { SectionInfoType } from '../types';
import Box from '@mui/material/Box';


type SectionSelectionProps = {
    sections: SectionInfoType[];
    scrollToSection: (sectionId: string | number) => void;
}

const SectionSelection: React.FC<SectionSelectionProps> = ({ sections, scrollToSection }) => {

    const scrollToSelectedSection = (sectionId: string | number) => {
        scrollToSection(sectionId);
    }

    return (<div id="section-selection">
        {
            sections?.length ?
                sections.map((section) => (
                    <GenericButton key={section.id} onClick={() => scrollToSelectedSection(section.id)} bold>
                        {section.name}
                    </GenericButton>
                ))
                :
                (
                    <Box className="section-selection-skeleton" gap={3}>
                        {[...Array(7)].map((_, i) => (
                            <Skeleton key={i} variant="rectangular" width={"8vw"} height={40} />
                        ))}
                    </Box>
                )
        }
    </div>
    )
}

export default SectionSelection;