import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BusinessCard from "./BusinessCard";
import { DiscountType, SectionInfoType } from "../types";
import Masonry from "@mui/lab/Masonry";
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';

type SectionProps = {
    sectionSpecs: SectionInfoType;
    sectionContent: DiscountType[];
}

const Section: React.FC<SectionProps> = ({ sectionSpecs, sectionContent }) => {

    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className='grey-box discounts-section' id={sectionSpecs.id + ""}>
            <div>
                <Typography variant='h2' className='section-title'>{sectionSpecs?.name}</Typography>
                <Typography variant='h3' className='section-subtitle'>{sectionSpecs?.description}</Typography>

                <Box className='discounts-container'>
                    <Masonry columns={{ xs: 1, sm: 3, md: 4 }} spacing={3}>
                        {
                            sectionContent?.map((discount) => {
                                return (<BusinessCard key={discount.id + ""} {...discount} />)
                            })
                        }
                    </Masonry>
                </Box>
            </div>
        </Box>
    )
}

export default Section;