import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import React from "react";
import { Typography } from "@mui/material";

const Footer: React.FC = () => {
    return (
        <footer>
            <Box id="footer">
                {/* todo footer + accessibility + responsive */}

                <Typography paragraph lineHeight={1.1} fontSize={'23px'}>
                    מצאתם טעות? משהו לא נראה בסדר?<br />
                    <Link href="https://wkf.ms/3FIEI4n" variant="inherit" target="_blank">כתבו לנו כאן</Link>
                </Typography>

                <Typography paragraph lineHeight={1.1} fontSize={'23px'}>
                    <Link target="_blank" href="terms-of-use.pdf">תנאי שימוש באתר</Link><br />
                    <Link target="_blank" href="accessibility.pdf" >הצהרת נגישות</Link>
                </Typography>

                <Typography paragraph lineHeight={1.8} fontSize={'20px'}>
                    האתר נבנה על ידי
                    <Link target="_blank" href="https://www.linkedin.com/in/ayelet-w/">אילת וסרמן</Link>
                    &nbsp; ו<Link target="_blank" href="mailto:noamir22@gmail.com">אמיר גורביץ׳</Link>
                </Typography>
            </Box>
        </footer>
    )
}

export default Footer;