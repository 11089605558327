import React from 'react';
import { Box, ButtonOwnProps } from '@mui/material';
import { GenericButton } from '../generic_components';

type LinksArray = {
    title: string;
    url: string;
    color?: ButtonOwnProps["color"];
}

const links: LinksArray[] = [
    {
        title: 'תחזית ירושלמית',
        url: "https://www.02ws.co.il/"
    },
    {
        title: 'החמ"ל האזרחי הירושלמי',
        url: "https://linktr.ee/onelevjlm"
    },
    {
        title: 'לוח משרות ירושלמיות',
        url: "https://www.jerusalem.muni.il/he/residents/employment/jobs/"
    },
    {
        title: 'עיריית ירושלים - ריכוז מידע',
        url: "https://www.jerusalem.muni.il/he/residents/strong-together/"
    },
    {
        title: 'קבוצת "ירושלמים ירושלמיות"',
        url: "https://www.facebook.com/groups/1592170434330768?locale=he_IL",
    },
    {
        title: 'הצטרפות לעסקים',
        url: "https://wkf.ms/3snwVpn",
        color: "secondary"
    }
];


const ImportantLinks: React.FC = () => {
    return (
        <Box id="important-links">
            <h2>קישורים חשובים</h2>

            <Box className="grey-box" id="links">
                {links.map((link) => {
                    return (
                        <GenericButton bold target="_blank" key={link.url} href={link.url} color={link.color ?? "primary"} text={link.title} />
                    )
                })}
            </Box>

        </Box>
    )
}

export default ImportantLinks;