import React from "react";
import { Button, ButtonOwnProps } from "@mui/material";
import clsx from "clsx";


const GenericButton: React.FC<ButtonOwnProps & { text?: string, target?: string, bold?: boolean, className?: string, onClick?: (i: any) => void }> = ({ className, text, color = "primary", variant = "outlined", children, href, bold = false, ...rest }) => {
    const buttonProps: ButtonOwnProps = {
        color,
        variant,
        children: text || children,
        ...rest,
    };

    if (href) {
        buttonProps.href = href;
    }

    return (
        <Button disableElevation {...buttonProps} className={clsx(bold && 'bold-text', className)}>
            {buttonProps.children}
        </Button>
    );
};

export default GenericButton;