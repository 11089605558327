// import React, { useEffect, useRef, useState } from 'react';
// import { Footer, Header, ImportantLinks, SectionSelection, Sections } from '../components';
// import Box from '@mui/material/Box';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { SectionInfoType } from '../types';
// import { GoogleSpreadsheet } from 'google-spreadsheet';

import React, { MutableRefObject, RefObject, createRef, useEffect, useRef, useState } from 'react';
import { Footer, Header, ImportantLinks, SectionSelection, Sections } from '../components';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SectionInfoType } from '../types';
import { GoogleSpreadsheet } from 'google-spreadsheet';

const HomePage: React.FC = () => {

    const isMoble = useMediaQuery('(max-width: 768px)');

    const [sections, setSections] = useState<SectionInfoType[]>([]);
    // const refs = useRef<Array<React.RefObject<any>>>([]); // an array of refs for each section - to be used for scrolling
    const refs: MutableRefObject<RefObject<unknown>[]> = useRef([]);

    const scrollToSection = (sectionId: string | number) => {
        const indexOfSection = sections.findIndex(section => section.id === sectionId);
        const sectionRef = refs.current[indexOfSection];
        if (sectionRef && sectionRef.current instanceof HTMLElement) {
            const topOffset = sectionRef.current.offsetTop - 60;
            window.scrollTo({ top: topOffset, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        const fetchSections = async () => {
            try {

                // get data from google sheets
                const doc = new GoogleSpreadsheet('1jsMBr5VoEhzgaJKPGSeKCy1nKSD_QR9qaITMnSk0bDU',
                    { apiKey: process.env.REACT_APP_SHEETS_API_KEY as string }); // replace with your API key
                await doc.loadInfo();
                const categoriesSheet = doc.sheetsByIndex[0];
                const rows = await categoriesSheet.getRows();
                const transformedRows = rows.map(row => row.toObject() as any);
                const sortedRows = transformedRows.sort((a, b) => a.appearance - b.appearance);
                setSections(sortedRows);

                refs.current = sortedRows.map((_, i) => refs.current[i] ?? createRef());
            } catch (error) {
                console.error('Error fetching sections:', error);
            }
        };

        fetchSections();

    }, []);

    return (
        <Box id="paddng">
            <Header />

            <ImportantLinks />

            {!isMoble && <SectionSelection scrollToSection={scrollToSection} sections={sections} />}

            <Sections refs={refs} sections={sections} />

            {isMoble && <SectionSelection scrollToSection={scrollToSection} sections={sections} />}

            <Footer />
        </Box>
    )
}
export default HomePage;