import { Box } from "@mui/material";
import React from "react";
import { useMediaQuery } from "@mui/material";

type HeaderProps = {
}

const Header: React.FC<HeaderProps> = () => {
    // query style - 1024 px and below is mobile
    const isMobile = useMediaQuery('(max-width: 1024px)');
    return (
        <header>
            <Box className="flex-center" id="heading">
                <img src="oreach_kavod_logo.svg" alt="לוגו אורח.ת כבוד" id="title_logo" />
                <Box>
                    <p>אורח.ת כבוד.<br />
                        יוזמה ירושלמית, שצמחה מהשטח – קוראת לקבל באהבה, ולהפוך אתכם, המשפחות מהצפון והדרום שהגיעו אלינו בעקבות המלחמה לאורחים ואורחות של כבוד בעיר.</p>

                    <p>בעלי ובעלות עסקים, ונותני שירות מקומיים מציעים הטבות ופינוקים לתושבי העיר החדשים.</p>

                    <p>אנחנו כאן בתקווה שהחיבור לירושלים, לאווירה שלה, ולאנשים שלה – יגרום לכם ולכן להרגיש כאן<br/>
                        קצת יותר בבית.</p>

                    <p>❤️</p>
                </Box>

                <Box>
                    <img src={`get_card_${isMobile ? 'mobile' : 'desktop'}.webp`} alt="לקבלת כרטיס אורח.ת כבוד" id="get_card" />
                </Box>
            </Box>
        </header>
    )
}

export default Header;