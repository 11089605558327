// import logo from './logo.svg'; // todo delete this
import './App.css';
import './styles/index';
import './styles/fonts.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { HomePage } from './pages';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#ED2024',
      },
      secondary: {
        main: '#FFCF36'
      },
    },
    typography: {
      fontFamily: [
        'Arbel',
        'sans-serif',
      ].join(','),
    },
  });


  return (
    <ThemeProvider theme={theme}>
      <div className="App" dir="rtl">
      
        <HomePage />

      </div>
    </ThemeProvider>
  );
}

export default App;
