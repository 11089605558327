import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const SkeletonBusinessCard: React.FC = () => {

    const heights = ['30px', '50px', '70px', '50px'];
    const randomHeightIndex = Math.floor(Math.random() * 3);
    const randomHeight = heights[randomHeightIndex];


    return (
        <Box className="skeleton-card">

            <Skeleton variant="text" height={37} />

            <Skeleton variant="rectangular" height={randomHeight} />

            <Box pt={1}>
                {[...Array(Math.floor(Math.random() * 3) + 2)].map((_, i) => (
                    <Skeleton key={i} variant="text" width={`${Math.floor(Math.random() * 40) + 60}%`} />
                ))}
                {/* <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" /> */}
            </Box>
            <Box pt={1}>
                <Skeleton variant="text" width="80%" />
            </Box>

            <Skeleton variant="rectangular" height={1} style={{ margin: '8px 0' }} />

            <Skeleton variant='rectangular' height={120} />

        </Box>
    );
}

export default React.memo(SkeletonBusinessCard);
