import React, { MutableRefObject, RefObject, useEffect } from "react";
import { Section, SkeletonBusinessCard } from ".";
import { DiscountType, SectionInfoType, SectionType } from "../types";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { Box, Skeleton } from "@mui/material";
import Masonry from "@mui/lab/Masonry";


type SectionsProps = {
    sections: SectionInfoType[];
    refs: MutableRefObject<RefObject<any>[]>;
}

const Sections: React.FC<SectionsProps> = ({ sections: sectionNames, refs }) => {

    const [sectionInfo, setSectionInfo] = React.useState<SectionType>({});

    useEffect(() => {
        async function fetchSections() {
            try {

                const doc = new GoogleSpreadsheet('1IP7YCGeMCSHRVo1B193RNnM4V2WUHnXV3a9CuZ8EZh4',
                    { apiKey: process.env.REACT_APP_SHEETS_API_KEY as string });

                doc.loadInfo().then(async () => {
                    const businesses = doc.sheetsByIndex[0];
                    const rows = await businesses.getRows();
                    const transformedObject: Record<string, DiscountType[]> = {};
                    rows.map(row => {
                        const currentRow = row.toObject() as DiscountType;
                        const currentCategory = currentRow.category || 'אחר';
                        if (!transformedObject[currentCategory]) {
                            transformedObject[currentCategory] = [];
                        }
                        transformedObject[currentCategory]?.push(currentRow);
                        return currentRow;
                    });

                    // Randomize the order of each transformedObject[currentCategory]
                    Object.keys(transformedObject).forEach(category => {
                        const categoryItems = transformedObject[category];
                        for (let i = categoryItems.length - 1; i > 0; i--) {
                            const j = Math.floor(Math.random() * (i + 1));
                            [categoryItems[i], categoryItems[j]] = [categoryItems[j], categoryItems[i]];
                        }
                    });

                    setSectionInfo(transformedObject);
                });

            } catch (error) {
                console.error('Error fetching sections:', error);
            }
        }

        fetchSections();
    }, []);

    console.log(sectionNames, sectionInfo)

    return (
        <div id="sections">
            {(!sectionNames?.length || !sectionInfo) ?
                <Box>
                    {[...Array(7)].map((_, i) => (
                        <Box className='skeleton-section' >

                            <Skeleton key={i} variant="rectangular" className="skeleton-title" />

                            <Box className='discounts-container'>
                                <Masonry columns={{ xs: 1, sm: 3, md: 4 }} spacing={3}>

                                    {[...Array(10)].map((_, i) => (
                                        <SkeletonBusinessCard key={i} />
                                    ))}

                                    <SkeletonBusinessCard />
                                </Masonry>
                            </Box>

                        </Box>
                    ))}
                </Box> :
                sectionNames?.map((sectionSpecs, i) => (
                    <div ref={refs.current[i]} key={sectionSpecs.id}>
                        <Section sectionSpecs={sectionSpecs} sectionContent={sectionInfo?.[sectionSpecs.name]} />
                    </div>
                ))
            }
        </div>
    )
};

export default Sections;