import React from "react";
import { DiscountType } from "../types";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { GenericButton } from "../generic_components";
import clsx from "clsx";

type BusinessCardProps = DiscountType & {
}



const BusinessCard: React.FC<BusinessCardProps> = ({ id, name, business_description, discount, small_image_id, image_name, link, address }) => {

    const [imageError, setImageError] = React.useState(false);

    // const imageSrc = `https://drive.google.com/uc?export=view&id=${small_image_id}`;
    // const imageSrcSet = `${imageSrc}&w=400 400w, ${imageSrc}&w=800 800w, ${imageSrc}&w=1200 1200w`;


    const error = () => {
        setImageError(true);
    }

    return (
        <Box className='discount-card'>
            <Typography variant='h3' className='discount-title'>{name}</Typography>
            {discount && <Typography variant="body1" className='discount-discount'>{discount}</Typography>}
            <Typography variant="body1" className='discount-description'>{business_description}</Typography>
            <Typography variant="body1" className='discount-location'>{address}</Typography>
            <div className="hr" />


{/* todo see why some of the images don't appear, for example: בירבזאר שוק מחנה יהודה
 */}
            <div className={clsx("placeholder", !imageError && small_image_id && 'image-exists')}>
                {!imageError && <img
                    onError={error}
                    src={`https://lh3.google.com/u/0/d/${small_image_id}`}
                    alt={image_name}
                    className='discount-image'
                    loading="lazy"
                    referrerPolicy="no-referrer"
                />}
            </div>

            <GenericButton className="more-info" variant='contained' text='פרטים נוספים' href={link} target="_blank" />
        </Box>
    )
}

export default BusinessCard;